import axios from 'axios'
import { call, put, takeEvery, all, select, takeLatest} from 'redux-saga/effects'
import cardActionTypes from './cardActionTypes'
import { formRedirect } from '../utils'
import { setFatalMessages } from "./cardActions"
import { PAYMENT_FIELD_NAMES } from '../../constants'
import posthog from 'posthog-js'

const apiURL = process.env.REACT_APP_API_URL ?? ''

function* pay({payload}) {
  const state = yield select()
  const formData = state.card.formData
  const {cardData} = payload

  yield put(setFatalMessages([]))
  let response

  posthog?.capture("form_pay", {
    $set: {
      form_pay: {
        ...formData,
        pan: cardData?.pan?.length ? '***' : null,
        expire_year: cardData?.expire_year?.length ? '***' : null,
        expire_month: cardData?.expire_month?.length ? '***' : null,
        cvc: cardData?.cvc?.length ? '***' : null,
      },
    }
  })

  try {
    response = yield call(axios, {
      method: 'post',
      url: `${apiURL}/form/pay`,
      data: {
        ...formData,

        pan: cardData.pan.replace(/ /g,''),
        expire_year: cardData.expire_year,
        expire_month: cardData.expire_month,
        cardholder: cardData.name,
        cvc: cardData.cvc,
        // todo: send name ?

        device_channel: "string",
        // device_browser_ip: "string", ???
        // device_browser_accept_header: "string", ???
        device_browser_java_enabled: window.navigator.javaEnabled(),
        device_browser_language: window.navigator.language,
        device_browser_color_depth: window.screen.colorDepth,
        device_browser_screen_height: window.screen.height,
        device_browser_screen_width: window.screen.width,
        device_browser_tz: new Date().getTimezoneOffset(),
        device_browser_user_agent: window.navigator.userAgent,
        // challenge_window_size: "string", ???
        // ip_address: "string", ???
      },
    })

  } catch (e) {
    console.log(e)
    response = e.response
  } finally {

    console.log(response)
    if (response) {
      const status = response?.status
      const data = response?.data
      const event = `form_pay_${status}`
      posthog?.capture(event, {
        $set: {
          [event]: {
            data,
            status,
          }
        }
      })
    }

    if(!response) {
      yield put(setFatalMessages('Something went wrong... try again'))
      yield put({
        type: cardActionTypes.PAY_FAILED,
        payload: {}
      })
    }

    if (response.data.status === 'redirect') {
      const {url, method, form_data} = response.data
      const redirectMethod = !!method?.length ? method : 'GET'
      formRedirect(url, redirectMethod, form_data)
      return
    }

    if (response.data.status === 'success') {
      // ???
    }

    const { message, field_errors, url, method } = response.data

    if (response.data.status === 'error') {
      if (!!url?.length) {
        const redirectMethod = !!method?.lenght ? method : 'GET'
        formRedirect(url, redirectMethod)
      }
      if(response.data.code === "FORM_PAY_ALREADY_SUBMITED") {
        yield put({
          type: cardActionTypes.PAY_FAILED,
          payload: {
            isReturn: true,
            returnUrl: response.data.internal_finish_url,
          }
        })
        if (message) {
          yield put(setFatalMessages([message]))
        }
        return
      }

      const errors = {}
      if (field_errors) {
        Object.entries(field_errors).forEach(([key, value]) => {
          if (key === 'expire_month' || key === 'expire_year') {
            if (!errors[PAYMENT_FIELD_NAMES.expDate]) {
              errors[PAYMENT_FIELD_NAMES.expDate] = []
            }
            errors[PAYMENT_FIELD_NAMES.expDate].push(...value)
          } else {
            errors[key] = value
          }
        })
      }

      if (message) {
        yield put(setFatalMessages([message]))
      }

      yield put({
        type: cardActionTypes.PAY_FAILED,
        payload: {errors}
      })
    }
  }
}

function* initial() {
  const state = yield select()
  const formData = state.card.formData

  let response
  posthog?.capture("form_init", {
    $set: {
      form_init: formData,
    }
  })

  try {
    response = yield call(axios, {
      method: 'post',
      url: `${apiURL}/form/init`,
      data: {
        ...formData,
      },
    })

    if (response.data.status !== 'success') {
      // todo: ?
    }

    yield put({
      type: cardActionTypes.INITIAL_FULFILLED,
      payload: {
        formData: {
          ...response.data,
        }
      }
    })
  } catch (e) {
    response = e.response

    if (response.data.status === 'error') {
      if(response.data.code === "FORM_PAY_ALREADY_SUBMITED") {
        yield put({
          type: cardActionTypes.INITIAL_FAILED,
          payload: {
            isReturn: true,
            returnUrl: response.data.internal_finish_url,
          }
        })
      }

      if(response.data.code === "WRONG_SIGNATURE_ERROR") {
        yield put({
          type: cardActionTypes.INITIAL_FAILED,
          payload: {
            isReturn: true,
            returnUrl: response.data.internal_finish_url,
          }
        })
      }

      const {message} = response.data
      if (message) {
        yield put(setFatalMessages([message]))
      }

      return
    }

    yield put({
      type: cardActionTypes.INITIAL_FAILED,
      payload: {}
    })
  } finally {
    if (response) {
      const status = response?.status
      const data = response?.data
      const event = `form_init_${status}`

      posthog?.capture(event, {
        $set: {
          [event]: {
            data,
            status,
          },
          invoice_id: data?.invoice_id,
        },
      })
    }
  }
}

export default function* cardSagas() {
  yield all([
    yield takeLatest(cardActionTypes.INITIAL_REQUEST, initial),
    yield takeLatest(cardActionTypes.PAY_REQUEST, pay),
  ])
}
