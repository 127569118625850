import finishActionTypes from './finishActionTypes'

const InitialState = {
  loading: true,
  sending: false,

  status: null,


  fatalMessages: {},
}

export default function finishReducer(state = InitialState, action) {
  const {type, payload} = action

  switch (type) {
    case finishActionTypes.SET_FATAL_MESSAGES: {
      return {
        ...state,
        fatalMessages: payload.fatalMessages ? [...payload.fatalMessages] : [],
      }
    }

    case finishActionTypes.STATUS_REQUEST: {
      return {
        ...state,
        loading: true,
      }
    }

    case finishActionTypes.STATUS_FULFILLED: {
      return {
        ...state,
        loading: false,
        status: payload.status,
      }
    }

    case finishActionTypes.STATUS_FAILED: {
      return {
        ...state,
        loading: false,
        status: payload.status,
      }
    }

    default: {
      return state
    }
  }
}
