import React, { useEffect, useRef, useState } from 'react'
import Cards from 'react-credit-cards'
import 'react-credit-cards/es/styles-compiled.css'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import styles from './PaymentForm.module.scss'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { pay, setCardFieldError } from '../../model/cardSagas/cardActions'
import CircularProgress from '@material-ui/core/CircularProgress'
import { CardMask } from './CardMask'
import { ExpiryMask } from './ExpiryMask'
import { CVCMask } from './CVCMask'
import { validate } from './validate'
import { ErrorMessage } from "../ErrorMessage/ErrorMessage";
import Divider from "@material-ui/core/Divider";
import Link from "@material-ui/core/Link";
import { Card } from "@material-ui/core";
import { PAYMENT_FIELD_NAMES } from './../../constants'

const defaultState = {
  [PAYMENT_FIELD_NAMES.cardNumber]: '',
  [PAYMENT_FIELD_NAMES.expDate]: '',
  [PAYMENT_FIELD_NAMES.cvv2]: '',
  [PAYMENT_FIELD_NAMES.nameoncard]: '',

  focus: '',
  cardMaxLength: 19,
  cardValid: false,
}

const nextIsUsed = {
  [PAYMENT_FIELD_NAMES.cardNumber]: false,
  [PAYMENT_FIELD_NAMES.expDate]: false,
  [PAYMENT_FIELD_NAMES.cvv2]: false,
  [PAYMENT_FIELD_NAMES.nameoncard]: false,
}

const cardFields = [
  PAYMENT_FIELD_NAMES.cardNumber,
  PAYMENT_FIELD_NAMES.nameoncard,
  PAYMENT_FIELD_NAMES.expDate,
  PAYMENT_FIELD_NAMES.cvv2
]

export const PaymentForm = props => {
  const {fatalMessages, formData} = props
  const {cancel_url} = formData
  const intl = useIntl()
  const [loadingTextIndex, setLoadingTextIndex] = useState(0);
  const [state, setState] = useState(defaultState)
  const dispatch = useDispatch()
  const card = useSelector(state => state.card)
  const fieldErrors = card.errors

  const loadingTexts = [
    intl.formatMessage({id: 'card.processing.1'}),
    intl.formatMessage({id: 'card.processing.2'}),
    intl.formatMessage({id: 'card.processing.3'}),
  ]

  useEffect(() => {
    if (!card.sending) {
      return
    }
    let tipIndex = 0
    setLoadingTextIndex(tipIndex)
    const interval = setInterval(() => {
      setLoadingTextIndex(tipIndex++)
    }, 10000)
    return () => {
      clearInterval(interval)
    }
  }, [card.sending]);

  useEffect(() => {
    validate.intl = intl
    validate.cardValid = state.cardValid
    validate.cardMaxLength = state.cardMaxLength
    try {
      validate.nameRequired = JSON.parse(formData.require_cardholder)
    } catch (e) {
      validate.nameRequired = false
    }
  })

  const handleInputFocus = (e) => {
    handleInputChange(e)
    setState({
      ...state,
      focus: e.target.name
    })
  }

  const handleInputChange = (e, skipValidate) => {
    const {name, value} = e.target
    const isActive = e.target === document.activeElement

    if (skipValidate) {
      dispatch(setCardFieldError(name, []))
      setState({
        ...state,
        [name]: value,
      })
      return
    }

    const {fixedValue, errors, next} = validate[name](value, state, isActive)
    dispatch(setCardFieldError(name, errors))
    setState({
      ...state,
      [name]: fixedValue,
    })
    if (next && e.type === "change" && !nextIsUsed[next]) {
      setTimeout(() => {
        const el = document.querySelector(`[name="${next}"]`)
        if (el) {
          el.focus()
          nextIsUsed[next] = true
        }
      })
    }
  }

  const processing = () => {
    let valid = true
    cardFields.forEach(name => {
      const {errors} = validate[name](state[name], state)
      if (errors.length) {
        valid = false
        dispatch(setCardFieldError(name, errors))
      }
    })
    if (!valid) {
      return
    }
    const data = {
      pan: state[PAYMENT_FIELD_NAMES.cardNumber],
      name: state[PAYMENT_FIELD_NAMES.nameoncard],
      cvc: state[PAYMENT_FIELD_NAMES.cvv2],
      expire_month: state[PAYMENT_FIELD_NAMES.expDate].slice(0, 2),
      expire_year: state[PAYMENT_FIELD_NAMES.expDate].slice(2, 4),
    }
    dispatch(pay(data))
  }

  return (
    <div className={styles.form}>
      <div>
        {!!(fatalMessages && fatalMessages.length) && (
          <ErrorMessage errors={fatalMessages}/>
        )}

        <div className={styles.flexCard}>
          {/*<Cards*/}
          {/*  cvc={state.cvc}*/}
          {/*  expiry={state.expiry}*/}
          {/*  focused={state.focus}*/}
          {/*  name={state.name}*/}
          {/*  number={state.pan}*/}
          {/*  locale={{valid: intl.formatMessage({id: 'card.sample.expiry'})}}*/}
          {/*  placeholders={{name: intl.formatMessage({id: 'card.sample.name'})}}*/}
          {/*  callback={({maxLength}, valid) => {*/}
          {/*    setState({*/}
          {/*      ...state,*/}
          {/*      cardMaxLength: maxLength,*/}
          {/*      cardValid: valid,*/}
          {/*    })*/}
          {/*  }}*/}
          {/*/>*/}

          <div className={styles.cardForm}>
            <div className={styles.cardLine}>
              <TextField
                autoComplete="cc-number"
                id="CardForm-PanInput"
                name={PAYMENT_FIELD_NAMES.cardNumber}
                disabled={card.sending}
                fullWidth
                value={state[PAYMENT_FIELD_NAMES.cardNumber]}
                type="text"
                label={intl.formatMessage({id: 'card.pan'})}
                variant="standard"
                inputMode="decimal"
                size="medium"
                placeholder="Номер карты"
                onChange={handleInputChange}
                onBlur={handleInputChange}
                onFocus={handleInputFocus}
                error={!!(fieldErrors[PAYMENT_FIELD_NAMES.cardNumber] && fieldErrors[PAYMENT_FIELD_NAMES.cardNumber].length)}
                helperText={fieldErrors[PAYMENT_FIELD_NAMES.cardNumber] && fieldErrors[PAYMENT_FIELD_NAMES.cardNumber].length
                  ? fieldErrors[PAYMENT_FIELD_NAMES.cardNumber][0]
                  : ''
                }
                InputProps={{ inputComponent: CardMask }}
                inputProps={{ inputMode: 'decimal' }}
              />
            </div>

            <div className={styles.cardLine}>
              <div className={styles.flex}>
                <TextField
                  autoComplete="cc-exp"
                  id="CardForm-ValidThruInput"
                  name={PAYMENT_FIELD_NAMES.expDate}
                  disabled={card.sending}
                  value={state[PAYMENT_FIELD_NAMES.expDate]}
                  className={styles.halfField}
                  type="text"
                  label={intl.formatMessage({id: 'card.expiry'})}
                  variant="standard"
                  size="medium"
                  placeholder="00 / 00"
                  onChange={handleInputChange}
                  onBlur={handleInputChange}
                  onFocus={handleInputFocus}
                  InputProps={{inputComponent: ExpiryMask}}
                  error={!!(fieldErrors[PAYMENT_FIELD_NAMES.expDate] && fieldErrors[PAYMENT_FIELD_NAMES.expDate].length)}
                  helperText={
                    fieldErrors[PAYMENT_FIELD_NAMES.expDate] && fieldErrors[PAYMENT_FIELD_NAMES.expDate].length
                      ? fieldErrors[PAYMENT_FIELD_NAMES.expDate][0]
                      : ''
                  }
                  inputProps={{inputMode: 'decimal'}}
                />
                <TextField
                  autoComplete="cc-csc"
                  id="CardForm-CvvInput"
                  name={PAYMENT_FIELD_NAMES.cvv2}
                  disabled={card.sending}
                  value={state[PAYMENT_FIELD_NAMES.cvv2]}
                  className={styles.halfField}
                  type='text'
                  label={intl.formatMessage({id: 'card.cvc'})}
                  variant="standard"
                  size="medium"
                  placeholder="CVV"
                  onChange={handleInputChange}
                  onBlur={handleInputChange}
                  onFocus={handleInputFocus}
                  InputProps={{inputComponent: CVCMask}}
                  error={!!(fieldErrors[PAYMENT_FIELD_NAMES.cvv2] && fieldErrors[PAYMENT_FIELD_NAMES.cvv2].length)}
                  helperText={fieldErrors[PAYMENT_FIELD_NAMES.cvv2] && fieldErrors[PAYMENT_FIELD_NAMES.cvv2].length
                    ? fieldErrors[PAYMENT_FIELD_NAMES.cvv2][0]
                    : ''
                  }
                  inputProps={{
                    inputMode: 'decimal',
                    textsec: "disc",
                    className: styles.cvv
                  }}
                />
              </div>
            </div>

            <div className={styles.cardLine}>
              <TextField
                autoComplete="cc-name"
                id="nameoncard"
                name={PAYMENT_FIELD_NAMES.nameoncard}
                disabled={card.sending}
                value={state[PAYMENT_FIELD_NAMES.nameoncard]}
                fullWidth
                type="text"
                label={intl.formatMessage({id: 'card.owner'})}
                variant="standard"
                size="medium"
                onChange={handleInputChange}
                onBlur={handleInputChange}
                onFocus={handleInputFocus}
                error={!!(fieldErrors[PAYMENT_FIELD_NAMES.nameoncard] && fieldErrors[PAYMENT_FIELD_NAMES.nameoncard].length)}
                helperText={fieldErrors[PAYMENT_FIELD_NAMES.nameoncard] && fieldErrors[PAYMENT_FIELD_NAMES.nameoncard].length
                  ? fieldErrors[PAYMENT_FIELD_NAMES.nameoncard][0]
                  : ''
                }
              />
            </div>
          </div>
        </div>

        <Button
          variant="contained"
          color="primary"
          className={styles.payBtn}
          onClick={processing}
          disabled={card.sending}
          id="CardForm-Submit"
        >
          {/*{card.sending && (*/}
          {/*  <CircularProgress size={12} className={styles.loader}/>*/}
          {/*)}*/}
          <FormattedMessage id='card.btn.pay'/>
        </Button>

        {card.sending && (
          <>
            <div className={styles.preloaderWrapper}>
              
              {/*<div className={styles.preloaderText}>*/}
              {/*  {loadingTexts[Math.min(loadingTextIndex, loadingTexts.length - 1)]}*/}
              {/*</div>*/}
              <Card className={styles.sending}>
                <div className={styles.caption}>
                  {intl.formatMessage({ id: 'card.sending.preloader.title' })}
                </div>
                <div className={styles.text}>
                  {intl.formatMessage({ id: 'card.sending.preloader.desc' })}
                </div>
                <div className={styles.grow}></div>
                <CircularProgress
                  className={styles.preloader}
                  size={32}
                  thickness={2}
                />
              </Card>
            </div>
          </>
        )}

        {cancel_url && (
          <>
            <Divider className={styles.divider}/>
            <Link className={styles.cancel} href={cancel_url}>
              <FormattedMessage id='confirm.btn.cancel'/>
            </Link>
          </>
        )}
      </div>

      <div className={styles.certLogo}>
        <div className={styles.pci}/>
        <div className={styles.visa}/>
        <div className={styles.mastercard}/>
      </div>

      <div className={styles.protected}>
        <div className={styles.title}>
          {intl.formatMessage({id: 'card.protected.caption.title'})}
        </div>
        <div className={styles.text}>
          {intl.formatMessage({id: 'card.protected.caption.desc'})}
        </div>
      </div>

    </div>
  )
}
