import React, { useCallback } from 'react'
import 'react-credit-cards/es/styles-compiled.css'
import Button from '@material-ui/core/Button'
import styles from './ReturnForm.module.scss'
import { FormattedMessage, useIntl } from 'react-intl'
import { ErrorMessage } from "../ErrorMessage/ErrorMessage"

export const ReturnForm = props => {
  const {fatalMessages, url} = props

  const callback = useCallback(() => {
    if (url) {
      document.location.href = url
    } else {
      window.history.back()
    }
  }, [url])

  return (
    <div className={styles.form}>
      <div>
        {!!(fatalMessages && fatalMessages.length) && (
          <ErrorMessage errors={fatalMessages}/>
        )}

        <Button
          variant="contained"
          color="primary"
          className={styles.payBtn}
          onClick={callback}
        >
          <FormattedMessage id='return.btn.return'/>
        </Button>

      </div>
    </div>
  )
}
