import styles from './Icon.module.scss'

export function Icon(props) {
  const {type} = props

  console.log('type', type)

  return (
    <div className={`${styles.icon} ${styles[type]}`}>
    </div>
  )
}
