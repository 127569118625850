import React, { useEffect, useState } from 'react'
import styles from './FinishPage.module.scss'
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import CircularProgress from "@material-ui/core/CircularProgress"
import { status } from "../../model/finishSagas/finishActions"

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import ReplayIcon from '@material-ui/icons/Replay'
import WarningIcon from '@material-ui/icons/Warning'
import { ReturnForm } from "../../components/RetrunForm/ReturnForm";
import { Icon } from "../../components/Icon/Icon";

const sbankInvoiceStatuses = {
  COMPLETE: 'complete',
  PENDING: 'pending',
  FAILED: 'failed',
  REFUNDED: 'refunded',
}

export const FinishPage = () => {
  const [state, setState] = useState({})
  const dispatch = useDispatch()
  const {order, description, amount, currency, merchant_id} = state
  const intl = useIntl()

  const finish = useSelector(state => state.finish)

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const data = {}
    for (const key of urlParams.keys()) {
      data[key] = urlParams.get(key)
    }
    setState(data)
    dispatch(status(data))
  }, [])

  useEffect(
    () => {
      const timer = setInterval(() => {
        if(finish.status === sbankInvoiceStatuses.PENDING) {
          dispatch(status(state))
        }
      }, 5e3)
      return () => {
        clearInterval(timer)
      };
    },
    [state, finish]
  );

  if (finish.loading) {
    return (
      <div>
        <div className={styles.preloaderWrapper}>
          <CircularProgress
            className={styles.preloader}
            size={120}
            thickness={3}
          />
          {/*<div className={styles.preloaderText}>*/}
          {/*  {loadingTexts[Math.min(loadingTextIndex, loadingTexts.length - 1)]}*/}
          {/*</div>*/}
        </div>
      </div>
    )
  }

  function renderStatus(status) {
    switch (status) {
      case sbankInvoiceStatuses.COMPLETE:
        return (
          // <CheckCircleOutlineIcon className={styles.success}/>
          <Icon type='success' />
        )
      case sbankInvoiceStatuses.FAILED:
        return (
          // <ErrorOutlineIcon color={"inherit"} className={styles.fail}/>
          <Icon type='error' />
        )
      case sbankInvoiceStatuses.PENDING:
        return (
          // <CircularProgress
          //   className={styles.pending}
          //   size={110}
          //   thickness={4}
          // />
          <Icon type="wait" />
        )
      case sbankInvoiceStatuses.REFUNDED:
        return (
          // <ReplayIcon className={styles.refund}/>
          <Icon type='success' />
        )
      case "exception":
        return (
          // <WarningIcon className={styles.warning}/>
          <Icon type='error' />
        )
    }
  }

  const now = new Date()

  return (
    <div className={styles.pageWrapper}>
      <div
        className={styles.page}
      >
        {/*<CardMedia>*/}
        {/*  <PaymentHeader*/}
        {/*    order={order}*/}
        {/*    description={description}*/}
        {/*    amount={amount}*/}
        {/*    currency={currency}*/}
        {/*  />*/}
        {/*</CardMedia>*/}

        <div className={styles.content}>

          <div className={styles.status}>
            {renderStatus(finish.status)}
          </div>

          <div className={styles.info}>
            <FormattedMessage id={`finish.${finish.status}`}/>
          </div>

          <div className={styles.desc}>
            <FormattedMessage id={`finish.${finish.status}.desc`} />
          </div>

          <ReturnForm />

          {state.invoice_id && (
            <div className={styles.debug}>
              <div>
                {/*<div>{endpoint_id}</div>*/}
                <div className={styles.version}>
                  <div>
                    {now.toLocaleDateString(intl.locale, { timeZone: 'UTC' })}
                    ,{" "}
                    {now.toLocaleTimeString(intl.locale, { timeZone: 'UTC' })}
                  </div>
                  ,{" "} ver. {process.env.REACT_APP_VERSION}
                </div>
                {state.invoice_id}
              </div>
            </div>
          )}

        </div>
      </div>
    </div>
  )
}
